import React from 'react'

const TermsCondition = (props) => {
    return (
        <div className="row">
            <div className="col-md-6 col-md-offset-3">
                <div className="sg-paragraph">
                    <div className="">
                        <h3 className="">Villkor</h3>
                    </div>
                    <div className="">
                        <div className="row">
                            <div className="col-md-12">
                                <form className="form-horizontal">
                                    <p>
                                    Attic Creativity Studio AB säljer era verk enligt överenskommelse.
Utbetalning av försäljningsintäkter sker senast en månad efter
försäljning, till det konto ni har uppgett. Kommissionsvillkor och
detaljer framgår i separat kontrakt.
                                    </p>
                                    <br></br>
                                    <p>
                                    <b>SAMTYCKESAVTAL FÖR PERSONUPPGIFTBEHANDLING OCH FÖRSÄLJNINGSHISTORIK</b><br></br>
                                    </p>
                                    <p>
   Split Second är en betaltjänst under Splitgrid AB, org nr 556972-8230


  Genom att godkänna detta avtal godkänner du som privatperson att
Splitgrid AB får behandla dina personuppgifter enligt
Dataskyddsförordningen (GDPR). Du samtycker även till att Splitgrid
hanterar och sparar din försäljningshistorik som sker i butiken.                                        
                                    </p>
                                    <div className="form-group sa-form-group">
                                        <div className="col-sm-9">
                                            <button type="button" className="btn btn-default sa-btn-success" id="add-row" onClick={() => props.acceptTermsCodition()}>Godk&auml;nn</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsCondition